.cherry-spins-facebook.retarget {
    .page-wrapper {
        background-image: url('../images/cherry-spins/retargeting/sweet-bonanza-background.jpeg');
    }

    @media (max-width: 991px) {
        .facebook-offer-retargeting {
            margin-top: 40px;
        }
    }

    // @media (max-width: 991px) {
    //     .page-wrapper {
    //         background-image: url('https://cdn2.softswiss.net/comboslots/b/pragmaticexternal/SweetBonanza.jpg');
    //     }
    // }

    // .facebook-offer-surtitle {
    //     font-size: 20px;
    //     color: #FFF;
    //     font-weight: bold;
    //     margin-bottom: 5px;
    // }

    // .facebook-offer-title {
    //     font-size: 40px;
    //     color: #FFF;
    //     font-weight: bold;
    //     margin-bottom: 15px;
    //     text-shadow: none;
    // }

    // @media (max-width: 991px) {
    //     .facebook-offer-title {
    //         font-size: 32px;
    //         color: #FFF;
    //         font-weight: bold;
    //         margin-bottom: 15px;
    //     }
    // }

    // .facebook-offer-container {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    // }

    // @media (max-width: 991px) {
    //     .facebook-offer-container {
    //         margin-top: 110px;
    //     }
    // }

    // .facebook-offer-wrapper {
    //     position: relative;
    //     max-width: 430px;
    // }

    // .facebook-offer-board {
    //     width: 100%;
    //     height: auto;
    // }

    // .facebook-offer-description {
    //     position: absolute;
    //     top: calc(50% - 36px);
    //     left: calc(50% - 130px);
    //     margin: 0;
    //     width: 260px;
    //     right: auto;
    //     font-size: 22px;
    //     font-weight: bold;
    //     text-align: center;
    // }

    .facebook-offer-button {
        margin-top: 50px !important;
        background-color: #d93744 !important;
        color: #FFF !important;
    }

    // .pulse-button:hover {
    //     -webkit-animation: none;
    //     animation: none;
    // }

    @keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgb(217 54 67 / 44%);
            -moz-box-shadow: 0 0 0 0 rgb(217 54 67 / 44%);
            box-shadow: 0 0 0 0 rgb(217 54 67 / 44%);
            transform: scale(0.93);
        }

        70% {
            -webkit-box-shadow: 0 0 0 10px rgb(217 54 67 / 44%);
            -moz-box-shadow: 0 0 0 10px rgb(217 54 67 / 44%);
            box-shadow: 0 0 0 10px rgb(217 54 67 / 44%);
            transform: scale(1.02);
        }

        100% {
            -webkit-box-shadow: 0 0 0 0 rgb(217 54 67 / 44%);
            -moz-box-shadow: 0 0 0 0 rgb(217 54 67 / 44%);
            box-shadow: 0 0 0 0 rgb(217 54 67 / 44%);
            transform: scale(0.93);
        }
    }

    .light-background {
        opacity: 1;
    }

    // @media (max-width: 991px) {
    //     .light-background {
    //         opacity: 0.15;
    //     }
    // }
}