.pageWrapper {
    background-color: black;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../images/olympus/background.jpeg');
}

@media (max-width: 991px) {
    .pageWrapper {
        background-image: url('../images/olympus/background.jpeg');
    }
}