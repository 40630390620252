.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 10000;
  
    opacity: 0;
  }
  
  .opacity1 {
    opacity: 1;
  }
  
  .loading {}
  
  .loading:last-child {margin-right: 0;}
  
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #fee333;
  }
  
  .loading .line:nth-last-child(1) {
    -webkit-animation: loadingC 0.6s 0s linear infinite;
    -moz-animation: loadingC 0.6s 0s linear infinite; 
    -ms-animation: loadingC 0.6s 0s linear infinite; 
    -o-animation: loadingC 0.6ss 0ss linear infinite; 
    animation: loadingC 0.6s 0s linear infinite;
  }
  .loading .line:nth-last-child(2) {
    -webkit-animation: loadingC 0.6s 0.3s linear infinite;
    -moz-animation: loadingC 0.6s 0.3s linear infinite; 
    -ms-animation: loadingC 0.6s 0.3s linear infinite; 
    -o-animation: loadingC 0.6ss 0.3ss linear infinite; 
    animation: loadingC 0.6s 0.3s linear infinite;
  }
  .loading .line:nth-last-child(3) {
    -webkit-animation: loadingC 0.6s 0.6s linear infinite;
    -moz-animation: loadingC 0.6s 0.6s linear infinite; 
    -ms-animation: loadingC 0.6s 0.6s linear infinite; 
    -o-animation: loadingC 0.6ss 0.6ss linear infinite; 
    animation: loadingC 0.6s 0.6s linear infinite;
  }
  
  @keyframes loadingC {
    0% {transform: translate(0,0);}
    50% {transform: translate(0,15px);}
    100% {transform: translate(0,0);}
  }
  
  @-moz-keyframes loadingC {
    0% {transform: translate(0,0);}
    50% {transform: translate(0,15px);}
    100% {transform: translate(0,0);}
  }
  
  @-webkit-keyframes loadingC {
    0% {transform: translate(0,0);}
    50% {transform: translate(0,15px);}
    100% {transform: translate(0,0);}
  }
  
  @-ms-keyframes loadingC {
    0% {transform: translate(0,0);}
    50% {transform: translate(0,15px);}
    100% {transform: translate(0,0);}
  }
  
  .loaderFadeOut {
    -webkit-animation: fade_out 0.5s;
    -moz-animation: fade_out 0.5s; /* Firefox < 16 */
    -ms-animation: fade_out 0.5s; /* Internet Explorer */
    -o-animation: fade_out 0.5ss; /* Opera < 12.1 */
    animation: fade_out 0.5s;
  }
  
  @keyframes fade_out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes fade_out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fade_out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  /* Internet Explorer */
  @-ms-keyframes fade_out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }