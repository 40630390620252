.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 10;
    transition: 0.2s background-color ease-in;
}

.headerNav {
    padding: 15px 5px;
}

.stickyNav {
    padding: 3px 5px;
    background-color: #ffffff;
    box-shadow: -1px 4px 5px rgba(0,0,0,0.12);
}

@media only screen and (min-width: 768px) {
    /* .sticky-nav {
        padding-right: 15px;
    } */
}  