.cherry-spins-slots {
    .page-wrapper {
        background-color: #121212;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../images/cherry-spins/background.png');
    }
    
    @media (max-width: 991px) {
        .page-wrapper {
            background-position: top;
            background-size: contain;
            background-image: url('../images/cherry-spins/background-mobile.png');
        }
    }
}