.cherry-spins-loader {
    .loading-container {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        background-color: #121212;
        z-index: 10000;

        opacity: 0;
    }

    .opacity-1 {
        opacity: 1;
    }

    .loading {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 70px;
    }

    .loading div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #D93744;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .loading div:nth-child(1) {
        left: 8px;
        animation: loading1 0.6s infinite;
    }

    .loading div:nth-child(2) {
        left: 8px;
        animation: loading2 0.6s infinite;
    }

    .loading div:nth-child(3) {
        left: 32px;
        animation: loading2 0.6s infinite;
    }

    .loading div:nth-child(4) {
        left: 56px;
        animation: loading3 0.6s infinite;
    }

    @keyframes loading1 {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes loading3 {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }

    @keyframes loading2 {
        0% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(24px, 0);
        }
    }

    .loader-fade-out {
        -webkit-animation: fade_out 0.5s;
        -moz-animation: fade_out 0.5s;
        /* Firefox < 16 */
        -ms-animation: fade_out 0.5s;
        /* Internet Explorer */
        -o-animation: fade_out 0.5ss;
        /* Opera < 12.1 */
        animation: fade_out 0.5s;
    }

    @keyframes fade_out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fade_out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fade_out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fade_out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}