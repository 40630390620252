.btn-spin-red {
    background-color: #d93744 !important;
    color: #fff !important;
    font-size: 20px !important;
    border-radius: 100px;
}

.btn-spin-red:hover {
    // background-color: #e74754 !important;
    color: #fff !important;
    font-size: 20px !important;
    transform: scale(105%);
}

.chances-color {
    // color: #25c0cd;
    color: #fee333;
}

.light-background {
    position: absolute;
    z-index: -1;
    height: 1000px;
    top: calc(50% - 500px);
    right: calc(50% - 500px);
    -webkit-animation: rotating 240s linear infinite;
    -moz-animation: rotating 240s linear infinite;
    -ms-animation: rotating 240s linear infinite;
    -o-animation: rotating 240s linear infinite;
    animation: rotating 240s linear infinite;
    opacity: 0.3;
}

@media (max-width: 767px) {
    .light-background {
        height: 550px;
        top: calc(50% - 300px);
        right: calc(50% - 275px);
        opacity: 0.15;
    }
}

@-webkit-keyframes rotating
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}