.bigbass-banner {
    position: absolute;
    right: 48%;
    bottom: 0;
    z-index: 1;

    .banner-image {
        height: 100vh;
    }

    @media only screen and (min-width: 1000px) and (max-width: 1199px) {
        right: 43%;
    }

    @media only screen and (max-width: 999px) {
        right: 0%;

        .banner-image {
            height: 100vh;
        }
    }

    @media only screen and (max-width: 767px) {
        // .banner-image {
        //     height: 80vh;
        // }
    }

    @media only screen and (max-width: 539px) {
        right: -5%;

        // .banner-image {
        //     height: 80vh;
        // }
    }

    @media only screen and (max-width: 320px) {
        // .banner-image {
        //     height: 80vh;
        // }
    }
}