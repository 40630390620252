.cherry-spins-nav {
    .nav {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 10;
        padding: 15px 50px;
    }

    @media (max-width: 767px) {
        .nav {
            padding: 15px 0;
        }
    }

    .logo {
        height: 32px;
    }

    .btn-register {
        text-decoration: none;
        border-radius: 20px;
        font-size: .875rem;
        font-weight: 600;
        padding: 0.5rem 1.75rem;
        background-color: #d93744;
        color: #fff;
        border: 2px solid #d93744;
        font-weight: bold;
    }

    .btn-logo {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    @media (max-width: 767px) {
        .logo {
            height: 27px;
        }
    }
}