.pageWrapper {
    background-color: black;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../images/orozino/desktop-background.jpg');
}

@media (max-width: 991px) {
    .pageWrapper {
        background-image: url('../images/orozino/mobile-background.jpg');
    }
}