h1 {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 55%);
}

.price {
    color: #fdfc1f;
}

.slotsSection {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slotWrapper {
    top: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto;
    overflow: hidden;
}

.slotMachine {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;
}

.reelsContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -5px;
    z-index: -50;

    width: 650px;
    height: 390px;
    padding: 47px 30px;
    padding-bottom: 56px;
}

.slotMask {
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;

    top: 45px;
    left: 43px;
    width: 565px;
    height: 292px;
}

.reel {
    width: 33.33%;
    overflow: hidden;
    z-index: 2;
    position: relative;
}

.slotIcon {
    position:absolute;
    width: 100%;
}

.handle {
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    height: 70px;
    width: 35px;
    right: -6px;
    top: 70px;
}

/* BUTTON */

.btnRollContainer {
    text-align: center;
    margin-bottom: 0px;
}

.btnRoll {
    width: 215px;
    margin: auto;
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
    .reelsContainer {
        width: 500px;
        height: 290px;
        padding: 31px 21px;
        padding-bottom: 36px;
    }

    .slotMask {
        top: 29px;
        left: 30px;
        width: 440px;
        height: 226px;
    }

    .handle {
        height: 60px;
        width: 30px;
        right: -9px;
        top: 45px;
    }
}

@media only screen and (max-width: 999px) {

    h1 {
        font-size: 1.5rem;
    }
    
    .slotsSection {
        height: calc(100vh - calc(100vh - 100%))
    }

    .reelsContainer {
        width: 290px;
        height: 192px;
        padding: 30px 13px;
        padding-bottom: 35px;
    }

    .slotMask {
        top: 29px;
        left: 20px;
        width: 252px;
        height: 130px;
    }

    .handle {
        height: 40px;
        width: 20px;
        right: -7px;
        top: 30px;
    }

    .btnRollContainer {
        margin-bottom: 30px;
    }
} 

/*---- ROTATION ANIMATION ------*/
.infiniteRotation {
    animation-name: slotRotation;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes slotRotation {
    0% {transform: translateY(0px);}
    /* responsive parameters */
    100% {transform: translateY(140px);}
    
}