.cherry-spins-facebook {
    .page-wrapper {
        background-color: #121212;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../images/cherry-spins/main-banner-2.png');
    }

    @media (max-width: 991px) {
        .page-wrapper {
            background-position: top;
            background-size: cover;
            background-image: url('../images/cherry-spins/main-banner-mobile-2.png');
        }
    }

    .facebook-offer-surtitle {
        font-size: 20px;
        color: #FFF;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .facebook-offer-title {
        font-size: 40px;
        color: #FFF;
        font-weight: bold;
        margin-bottom: 15px;
        text-shadow: none;
    }

    @media (max-width: 991px) {
        .facebook-offer-title {
            font-size: 32px;
            color: #FFF;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }

    .facebook-offer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 991px) {
        .facebook-offer-container {
            margin-top: 110px;
        }
    }

    .facebook-offer-wrapper {
        position: relative;
        max-width: 430px;
    }

    .facebook-offer-board {
        width: 100%;
        height: auto;
    }

    .facebook-offer-description {
        position: absolute;
        top: calc(50% - 36px);
        left: calc(50% - 130px);
        margin: 0;
        width: 260px;
        right: auto;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
    }

    .facebook-offer-button {
        margin-top: 25px !important;
        font-size: 22px !important;
        border-radius: 50px !important;
        animation: pulse 2s infinite;
        -webkit-animation: pulse 2s infinite;
    }

    .pulse-button:hover {
        -webkit-animation: none;
        animation: none;
    }

    @keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
            -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
            box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
            transform: scale(0.93);
        }

        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
            -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
            box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
            transform: scale(1.02);
        }

        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
            -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
            box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
            transform: scale(0.93);
        }
    }

    .light-background {
        position: absolute;
        z-index: -1;
        height: 800px;
        top: calc(50% - 400px);
        right: calc(50% - 400px);
        -webkit-animation: rotating 240s linear infinite;
        -moz-animation: rotating 240s linear infinite;
        -ms-animation: rotating 240s linear infinite;
        -o-animation: rotating 240s linear infinite;
        animation: rotating 240s linear infinite;
        opacity: 0.5;
    }

    @media (max-width: 991px) {
        .light-background {
            opacity: 0.15;
        }
    }
}