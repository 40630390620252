html {
    /* scroll-behavior: smooth; */
    background: #121212;
}

.dtpcnt {
    opacity: 0;
}

.async-hide {
    opacity: 0 !important
}

html::-webkit-scrollbar {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
button {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2 {
    font-weight: 800;
}

h3 {}

.af-form {
    max-width: none;
    width: 100%;
}

.btn-primary {
    padding: 13px 40px;
    border-radius: 30px;
}

.main-cta,
.main-cta:hover,
.main-cta:focus,
.af-button>button,
.af-button>a {
    padding: 8px 15px;
    border: none;
    border-radius: 30px !important;
    color: #212121 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    box-shadow: 4px 7px 0 rgba(0, 0, 0, 0.10);
    margin: 7px 0px !important;
}

.af-button>a,
.af-button>a:hover {
    display: block;
    text-decoration: none;
}

.af-button>a:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    transition: background-image 0.3s ease;
}

.cta-large,
.cta-large:focus,
.cta-large:hover,
.af-button>button,
.af-button>a {
    padding: 20px 40px;
    font-size: 16px !important;
    font-weight: 800 !important;
}

.main-cta,
.af-button button[type="button"],
.af-button>a {
    background-color: #FEE333 !important;
}

.main-cta:hover {
    background-color: #e7ce29;
}

.main-cta:focus {
    background-color: #FEE333;
}

/* .main-cta-olympus {
    background-color: #2bb6ff !important;
    color: #FFF !important;
}

.main-cta-olympus:hover {
    background-color: #1d94d4;
    color: #FFF !important;
}

.main-cta-olympus:focus {
    background-color: #2bb6ff;
    color: #FFF !important;
} */

.overlay {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* background-color: #e9e9e9; */
    /* z-index: 10;*/
}

.overlay-content {
    overflow-y: scroll;
    padding: 20px;
    max-width: 768px;
    z-index: 15;
}

.overlay-content::-webkit-scrollbar {
    display: none;
}

.overlay-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

button.close {
    position: absolute;
    right: 20px;
    top: 20px;
}

/* .modal-backdrop {
    background-image: url(../data/gifs/waterfall-bg.gif);
    background-repeat: no-repeat;
    background-size: cover;
} */

/* .modal-backdrop.show {
    opacity: 1;
} */

.modal-content {
    border-radius: 20px;
}

.glow-on-hover {
    position: relative;
    z-index: 0;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(to right, #ce9512, #fee333, #d8a320, #d29c1a);
    position: absolute;
    top: -3px;
    left: -3px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(3px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 60px;
}

/* .glow-on-hover:active {
    color: #000
} */

/* .glow-on-hover:active:after {
    background: transparent;
} */

.glow-on-hover:before {
    opacity: 1;
}

@keyframes glowing {
    0% {
        background-position: 0% 0;
        filter: blur(3px);
        z-index: -2;
    }

    50% {
        background-position: 400% 0;
        filter: blur(3px);
        z-index: -2;
    }

    100% {
        background-position: 0% 0;
        filter: blur(3px);
        z-index: -2;
    }
}

.glow-on-hover:after {
    z-index: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fee333 !important;
    left: 0;
    top: 0;
    border-radius: 30px;
}



/* .orozino-light {
    position: absolute;
    top: 16.5%;
    left: 17.5%;
    width: 80px;
    height: 80px;
    animation-name: spin;
    animation-duration: 12000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@media screen and (max-width: 999px) {
    .orozino-light {
        top: 19.5%;
        left: 12%;
    }
}  */

/* @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
} */

.rolling {
    animation: blinkingText 1.5s infinite;
    -webkit-animation: blinkingText 1.5s infinite;
}

/* BUTTON ANIMATION */
@keyframes blinkingText {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}