.bannerImage {
    position: absolute;
    bottom: 0;
    right: 36%;
    height: 92vh;
}

.bannerImageAdult {
    position: absolute;
    bottom: 0;
    right: 42%;
    height: 92vh;
}

.bannerImagePool {
    position: absolute;
    bottom: calc(50% - 80px);
    right: 59%;
    max-height: 20vh;
    transform: translateX(0px) translateY(0px) rotate(0deg);
    transform-origin: center;
	animation: float 7s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translateX(0px) translatey(0px) rotate(0deg);
	}
    50% {
        transform: translateX(-2px) translatey(-4px) rotate(1deg);
    }
    100% {
		transform: translateX(0px) translatey(0px) rotate(0deg);
    }
}


/* @keyframes float {
	0% {
		transform: translatey(0px) rotate(0deg);
	}
	20% {
		transform: translatey(-2.5px) rotate(-0.5deg);
    }
    35% {
		transform: translatey(-4px) rotate(-0.5deg);
    }
    45% {
        transform: translatey(-4.7px) rotate(0deg);
    }
    75% {
		transform: translatey(-4px) rotate(0.5deg);
    }
    85% {
		transform: translatey(-2px) rotate(0.5deg);
    }
    100% {
		transform: translatey(0px) rotate(0deg);
    }
} */

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
    .banneImage {
        right: 31%;
    }

    .bannerImageAdult {
        right: 40%;
    }

    .bannerImagePool{
        position: absolute;
        bottom: calc(50% - 80px);
        right: 56%;
    }
}

@media only screen and (max-width: 999px) {
    .bannerImage {
        left: 15%;
        right: 0;
        height: 80vh;
    }

    .bannerImageAdult {
        left: 44%;
        right: 0;
        height: 80vh;
    }

    .bannerImagePool{
        position: absolute;
        bottom: calc(50% - 80px);
        right: 59%;
    }
}

@media only screen and (max-width: 767px) {
    .bannerImage {
        left: 15%;
        right: 0;
        height: 80vh;
    }

    .bannerImageAdult {
        left: 25%;
        right: 0;
        height: 85vh;
    }

    .bannerImagePool {
        top: 18%;
        right: calc(50% - 22vh);
        max-height: 16vh;
    }
    
}

@media only screen and (max-width: 375px) {
    .bannerImage {
        left: 15%;
        right: 0;
        height: 80vh;
    }

    .bannerImageAdult {
        left: 21%;
        right: 0;
        height: 87vh;
    }

    .bannerImagePool {
        top: 18%;
        right: calc(50% - 21vh);
        max-height: 15vh;
    }
    
}

@media only screen and (max-width: 320px) {
    .bannerImage {
        left: 15%;
        right: 0;
        height: 90vh;
    }

    .bannerImageAdult {
        left: 13%;
        right: 0;
        height: 90vh;
    }

    .bannerImagePool {
        top: 10%;
        right: calc(50% - 22vh);
        max-height: 16vh;
    }
} 